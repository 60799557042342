<template>
  <div class="profile">
    <div
      v-if="!isEditing"
      class="profile__info"
      :class="{ ['--no-profile']: !profile.exists && !isCurrentUser}"
      >
      <UserInfo :profile="profile" />
      <button
        class="profile__edit__button"
        v-if="isCurrentUser"
        @click="toggleIsEditing"
      >Edit</button>
    </div>
    <div v-if="isEditing" class="profile__edit">
      <UpdateProfileForm
        class="profile__edit__form"
        :eth-address="account"
        :profile="profile"
        @submit="handleUpdateProfile"
        @cancel="toggleIsEditing"
      />
    </div>
    <div class="profile__created" v-if="createdMedia.length > 0">
      <h2 class="profile__created__title">Created</h2>
      <Grid
        class="profile__created__grid"
        :items="createdMedia"
        :cols="3"
        v-slot="slotProps"
        >
        <GridItem
          class="profile__created__item --black-background"
          :item="slotProps.item"
          :type="slotProps.item.type"
        />
      </Grid>
    </div>
    <div class="profile__collection" v-if="ownedMedia.length > 0">
      <h2 class="profile__collection__title">Collection</h2>
      <Grid
        class="profile__collection__grid"
        :items="ownedMedia"
        :cols="3"
        v-slot="slotProps"
        >
        <GridItem
          class="profile__collection__item"
          :item="slotProps.item"
          :type="slotProps.item.type"
        />
      </Grid>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, onUnmounted, watch, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import UserInfo from "./UserInfo.vue";
import UpdateProfileForm from './UpdateProfileForm.vue'
import Grid from "./Grid.vue"
import GridItem from "./GridItem.vue"

export default defineComponent({
  components: {
    UserInfo,
    UpdateProfileForm,
    Grid,
    GridItem,
  },
  setup() {

    const store = useStore()
    const route = useRoute()

    const account = computed(() => store.state.user.account)
    const credentials = computed(() => store.state.user.credentials)
    const profile = computed(() => store.state.user.profile.user)
    const ownedMedia = computed(() => store.state.user.profile.ownedMedia)
    const ownedMediaLoaded = computed(() => store.state.user.profile.ownedMediaLoaded)
    const createdMedia = computed(() => store.state.user.profile.createdMedia)
    const createdMediaLoaded = computed(() => store.state.user.profile.createdMediaLoaded)

    const reset = () => store.dispatch("user/profile/reset")
    const loadProfileFromDiplsayName = (displayName) => store.dispatch("user/profile/loadFromDisplayName", displayName)
    const loadProfile = (ethAddress) => store.dispatch("user/profile/load", ethAddress)
    const updateProfile = (newProfile) => store.dispatch("user/profile/update", newProfile)

    // TODO: add saves/alts
    // const saves = computed(() => store.state.user.profile.saves)
    // const savesLoaded = computed(() => store.state.user.profile.savesLoaded)

    const isCurrentUser = computed(() => route.params.ethAddress === account.value)
    const isEthAddress = computed(() => route.params.ethAddress.indexOf('0x') === 0)

    const handleLoadProfile = async () => {
      if(isEthAddress.value){
        loadProfile(route.params.ethAddress)
      } else {
        loadProfileFromDiplsayName(route.params.ethAddress)
      }
    }

    const isEditing = ref(false)
    const toggleIsEditing = () => isEditing.value = !isEditing.value

    const handleUpdateProfile = async (newProfile) => {
      if(isEditing) toggleIsEditing()
      updateProfile(newProfile)
    }

    onMounted(() => {

      // load profile
      if(route.params.ethAddress !== undefined){
        handleLoadProfile()
      }

      // load when navigate to another profile
      watch(
        () => route.params.ethAddress,
        () => {
          reset()
          if(route.params.ethAddress !== undefined){
            handleLoadProfile()
          }
        }
      );
    })

    onUnmounted(() => {
       reset()
    })

    return {
      account,
      credentials,
      isCurrentUser,
      profile,
      handleUpdateProfile,
      ownedMedia,
      ownedMediaLoaded,
      createdMedia,
      createdMediaLoaded,
      isEditing,
      toggleIsEditing
    };
  },
});
</script>

<style lang="scss" scoped>
  .profile{
    &__info{
      max-width: 45em;
      padding: $margin;
      &.--no-profile {
        font-size: 250%;
        font-weight: bold;
      }
    }
    &__edit{
      padding: $margin;
      &__button{
        @include button;
      }
    }

    &__created{
      background: $primary-color;
      padding: $margin 0;
      &__title{
        color: $primary-background;
        padding: 0 $margin;
        margin: 0;
      }
    }

    &__collection{
      padding: $margin 0;
      &__title{
        padding: 0 $margin;
        margin: 0;
      }
    }
  }
</style>
