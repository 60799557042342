<template>
  <div class="user hide-profile-image">
    <div class="profile-image" />
    <div class="user-details">
      <h1 class="display-name">
        {{ profile.displayName }}
      </h1>
      <eth-address
        class="bold big"
        :eth-address="profile.ethAccount"
      />
      <div
        v-if="profile.parsedBio"
        class="bio-container"
      >
        <div
          class="bio"
          v-html="profile.parsedBio"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent} from "vue"
import EthAddress from './EthAddress.vue'

export default defineComponent({
  components:{
    EthAddress
  },
  props:{
    profile: Object,
  },
  setup() {
    return {
    }
  },
})
</script>

<style lang="scss" scoped>
  .bio-container{
    max-width: 600px;
  }
  .bio{
    white-space: pre-wrap;
  }
</style>
