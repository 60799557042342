<template>
  <div class="profile-form">
    <h2 class="profile-form__heading">{{ formType }} Profile</h2>
    <form @submit.prevent="updateProfile">
      <div class="profile-form__eth-address">
        <label class="profile-form__eth-address__label">eth address</label>
        <eth-address
          class="big bold"
          :eth-address="ethAddress"
        />
      </div>
      <div class="profile-form__input-container">
        <label class="profile-form__input-container__label" for="display-name">
          display name
        </label>
        <input
          v-model="newProfile.displayName"
          class="profile-form__input-container__input"
          type="text"
          name="display-name"
          :pattern="displayNamePattern"
        >
        <ul class="profile-form__input-container__rules">
          <li> Must be between 4-20 characters</li>
        </ul>
      </div>
      <div class="profile-form__input-container">
        <label class="profile-form__input-container__label" for="bio">
          bio
        </label>
        <textarea
          v-model="newProfile.bio"
          class="profile-form__input-container__textarea"
          type="text"
          name="bio"
          maxlength="255"
          rows="5"
        />
        <ul class="profile-form__input-container__rules">
          <li>Max 255 characters</li>
          <li>Markdown syntax is supported
            <ul class="profile-form__input-container__sub-rules">
              <li>Link: [label](https://some-link.com)</li>
              <li>Itallic: *some italic text*</li>
              <li>Bold: **some bold text**</li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="profile-form__buttons">
        <button class="profile-form__cancel-button" type="button" @click="cancel">
          Cancel
        </button>
        <button class="profile-form__submit-button" type="submit">
          {{ formType }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent, reactive, ref} from "vue"
import EthAddress from './EthAddress.vue'

export default defineComponent({
  components:{
    EthAddress
  },
  props:{
    ethAddress: String,
    profile: Object
  },
  emits: ['submit', 'random'],
  setup(props, { emit }) {

    const formType = ref( props.profile ? 'Update' : 'Create')

    // Update Profile
    const newProfile = reactive({
      displayName: props.profile ?  props.profile.displayName : null,
      bio: props.profile ?  props.profile.bio : null,
    })

    // taken form here: https://stackoverflow.com/questions/12018245/regular-expression-to-validate-username
    // const displayNamePattern = ref('^(?=.{4,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$')
    const displayNamePattern = ref('(?=.{3,20}$)([^]*)')

    const updateProfile = () => {
      emit('submit', { displayName: newProfile.displayName.toLowerCase(), bio: newProfile.bio})
    }

    const cancel = () => emit('cancel')

    return {
      newProfile,
      updateProfile,
      displayNamePattern,
      formType,
      cancel
    }
  },
})
</script>

<style lang="scss" scoped>
  .profile-form{
    max-width: 45em;
    // margin: auto;
    &__heading{}
    &__eth-address{
      margin-bottom: $margin;
      &__label{
        display: block;
        font-weight: 900;
      }
    }
    &__input-container{
      margin-bottom: $margin;
      &__label{
        display: block;
        font-weight: 900;
      }
      &__input, &__textarea {
        width: calc(100% - (($margin-small * 2) + ($border-width *2)));
        border-radius: 0px;
        border-style: solid;
        border-color: $tertiary-color;
        border-width: $border-width;
        padding: $margin-small;
        font-size: 120%;
        &:focus{
          outline: none;
          border-style: solid;
          border-color: $primary-color;
        }
      }
      &__rules, &__sub-rules{
        color: $tertiary-color;
        list-style-type: "- ";
      }
    }
    &__buttons{
      display: flex;
      justify-content: space-between;
    }
    &__submit-button, &__cancel-button{
      @include button;
    }
  }
</style>